import React from 'react'
import DropZone from './dropZone';
import { Grid } from '@material-ui/core';

function UploadPage({setProgressPageId, retailerDataProp, displayClient}) {
    return (
        <div className="UploadPage" style={{ paddingBottom: 0 }}>
            <Grid container direction='row' style={{ backgroundColor: '#e4ebf5' }}>
                <Grid item lg={12} style={{}} >
                    <DropZone retailerDataProp={retailerDataProp} displayClient={displayClient} setProgressPageId={setProgressPageId}/>
                </Grid>
            </Grid>
        </div >
    );
}

export default UploadPage;
