import { useRoutes } from 'react-router-dom';
import DashboardLayout from '../../components/dashboard';
import Dashboard from '../../content/dashboard/Dashboard';
import Error from '../../content/Error';
import UploadDataPage from '../../content/uploadData/UploadDataPage';
import NoLayout from '../NoLayout';

export default function RouterInit() {
    const routes = useRoutes([
        {
            path: 'error',
            element: <NoLayout />,
            children: [
                { path: 'error', element: <Error /> },

            ],
        },
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'upload', element: <UploadDataPage /> },

            ],
        }
    ]);

    return routes;
}