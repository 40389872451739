import "react-dropzone-uploader/dist/styles.css";
import { useContext } from 'react'
import Dropzone from "react-dropzone-uploader";
import { Box, Grid } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import $ from 'jquery';
import ListItem from '@mui/material/ListItem';



function DropZone({ setProgressPageId, retailerDataProp }) {
    const { user } = useAuth0();
   
    const handleValidation = (event) => {
        const retailerName = event.file.name.split(' ')[0]
        let pickedRetailer = retailerDataProp.fileName ? retailerDataProp.fileName : retailerDataProp.retailer.replaceAll(" ", "")
        const pickedWed = retailerDataProp.weekend.replaceAll("-", "")
        let wed = event.file.name.split(' ')[1]

        if (retailerName !== pickedRetailer || wed.split('.')[0] !== pickedWed) {
            $(".dzu-submitButtonContainer").hide()
            return `ERROR : The file's name should include ${pickedRetailer} ${pickedWed}`
        }

        return false
    }
    const handleSubmit = async (event) => {
        setProgressPageId(event[0].file)
    }


    return (
        <Box sx={{ boxShadow: 10 }} style={{ paddingBottom: 30, paddingLeft: 30, paddingRight: 30, paddingTop: 0 }}>
            <Grid container style={{ padding: '25px 15px 0px' }}>
                <Grid item lg={5} sx={{width:'100%'}}>
                    <Dropzone
                        onSubmit={handleSubmit}
                        validate={handleValidation}
                        onChangeStatus={({meta}, status)=> {
                            console.log(status, meta)
                        }}
                        //getUploadParams={getUploadParams}
                        inputContent={
                            <Grid container direction='column' justifyContent="center"
                                alignItems="center">
                                <Box display="flex"
                                    justifyContent="center"
                                    alignItems="center" sx={{ width: 100, height: 100, borderRadius: 10 }} style={{ backgroundColor: '#ECECEC' }}>
                                    <FontAwesomeIcon
                                        style={{
                                            color: "#949494",
                                            fontSize: 50,
                                        }}
                                        icon={faCloudUploadAlt}
                                    />
                                </Box>
                                <Typography style={{ fontWeight: 'bold', color: 'black', paddingTop: 15 }}>Drop a file here or click to upload </Typography>
                                <Typography style={{ fontWeight: 'bold', color: 'black', paddingTop: 15 }}>Max file size: 10MB </Typography>
                            </Grid>}

                        styles={{ dropzone: { minHeight: 350, overflow: "hidden", borderStyle: "dashed", backgroundColor: '#fff' } }}
                        maxSizeBytes={1024 * 1024 * 10}
                        maxFiles={1}
                        multiple={false}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />

                </Grid>
                <Grid item lg={7} style={{ margin: 'auto', paddingLeft: '40px' }}>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Retailer:</Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.retailer} </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Brand:</Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.client_name}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Week-Ending Date: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.weekend} </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Currency: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.currency} </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>File Name: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.fileName} {retailerDataProp.weekend.replaceAll("-", "")}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>File Type: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> .xlsx </Typography>
                    </ListItem>
                </Grid>
            </Grid>

        </Box >
    );
}

export default DropZone;
