/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useContext, useState, useRef } from "react";
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth0 } from '@auth0/auth0-react';
import { Divider } from "@mui/material";
import { ApiContext } from '../../contexts/ApiContext';
import { v4 } from 'uuid';
import { useReducer } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
// const { logger } = require("../../../../services/logService")

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
    left: 'calc(-50% + 13px)',
    right: 'calc(50% + 13px)',
  },

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#ccc',
    },
  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#B2ECB3',
    },
  },

  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#ccc',
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 2,
    backgroundColor:
      '#ccc',
    borderRadius: 10,
  },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
  color: '#ccc',
  display: 'flex',
  height: 22,
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#ccc',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#0FB559',
    zIndex: 1,
    fontSize: 35,

  },
  '& .QontoStepIcon-errorIcon': {
    color: '#FFDDE3',
    zIndex: 1,
    fontSize: 40,

  },
  '& .QontoStepIcon-circle': {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const getStatusIcon = (val) => {
  switch (val) {
    case -1:
      return (<CancelIcon sx={{ color: '#FFDDE3', borderRadius: 6, bgcolor: '#FF0F3C', fontSize: 20 }} />)
    case 1:
      return (<CheckIcon sx={{ color: '#0FB559', border: 2, borderRadius: 6, bgcolor: '#C5F1C6', fontSize: 20 }} />)
    case 0:
      return (<ErrorIcon sx={{ color: '#FFFDD0', borderRadius: 6, bgcolor: '#E49B0F', fontSize: 20 }} />)
  }
}

const getStatusColor = (val) => {
  switch (val) {
    case -1:
      return "#FF0F3C"
    case 1:
      return "#0FB559"
    case 0:
      return "#E49B0F"
  }
}

const getUploadSuccessComponent = (val, upload) => {
  if (val === -1 && upload === false) {
    return <Typography align='center' variant='h6' fontWeight={'bold'} sx={{ fontSize: '1.1rem', p: '35px 20px', color: '#FF0F3C' }} >Your upload was not successful. Please edit your file and try uploading it again.</Typography>
  }
  else if (val === 1 && upload === true) {
    return <Typography align='center' variant='h6' fontWeight={'bold'} sx={{ p: 3, color: '#00B050' }} >Your submission was successful! The data will be available in your SKYPAD app on the next business day.</Typography>
  }
  else if (val === 1 && upload === false) {
    return <Typography align='center' variant='h6' fontWeight={'bold'} sx={{ p: 3, color: '#00B050' }} >Your submission is in the expected format! <br></br> To upload the data to SKYPAD, click Submit below.</Typography>
  }
}

const steps = [0, 1, 2, 3];

const DownloadSummaryBtn = styled(Button)`
  color: #595959;
  background-color: #D9D9D9; 
  width:195px;
  font-size:15px;
  height:36px;
  :hover {
    color: #595959;
    background-color:#BEBEBE;
  }
`;

const CancelBtn = styled(Button)`
  color: #595959;
  background-color: #ffffff; 
  border:2px solid #5569FF;
  color: #5569FF;
  font-size: 15px;
  height:36px;
  width:195px;
  :hover {
    color: #595959;
    background-color:#1A45FE;
  }
`;

const SubmitBtn = styled(Button)`
  background-color: #5569FF; 
  color: #ffffff;
  font-size: 15px;
  width:195px;
  height:36px;
  :hover {
    color: #ffffff;
    background-color:#1A45FE;
  }
`;


export default function ProgressBar({ file, retailerDataProp, abortController, setStillLoading, setSumitted, socketProp }) {
  const [boxContaint, setBoxContaint] = React.useState({ activeStep: 0, title: '', type: '', status: true, value: [] })
  const [upload, setUpload] = React.useState(false)
  const { user } = useAuth0()
  const { LogAPI, SubmitAPI, ValidationAPI, clientLogAPI } = useContext(ApiContext);
  const [loading, setLoading] = useState(true);
  const [clicked, setClick] = useState(false);
  // const abortController = new AbortController();
  const progressPageId1 = useRef(v4());

  let progressPageId = progressPageId1.current;

  useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      ValidationAPI(formData, retailerDataProp.client_id, retailerDataProp.retailer, progressPageId, user.nickname, abortController.signal)
        .then((data) => {
          data.forEach(element => {
            handleNext(JSON.parse(element));
          });
          setLoading(false)
          setStillLoading(false)
        });
    } catch (error) {
      console.log(error)
    }
    // let wsURL = `wss://${window.location.host}/websockets`;
    // if (window.location.hostname === "localhost") {
    //   wsURL = 'ws://127.0.0.1:3839/websockets';
    // }

    // const client = new W3CWebSocket(wsURL, progressPageId);

    // client.onopen = () => {
    //   console.log('WebSocket Client Connected');
    // };


    // client.onmessage = (message) => {
    //   let msgData = JSON.parse(message.data);
    //   handleNext(msgData);
    // };
  }, [])

  const setValues = (socketData, prevState) => {
    let tempArr = [...prevState.value, socketData]
    let tempAS = (socketData.type === "Check Mark") ? prevState.activeStep + 1 : prevState.activeStep;
    let tempStatus = socketData.statusButton
    let tempTitle = [...prevState.title, socketData.category]
    let tempType = socketData.type
    return { activeStep: tempAS, title: tempTitle, type: tempType, status: tempStatus, value: tempArr, stepVal: socketData.value, socketData: socketData };
  }

  const handleLog = async (event) => {
    LogAPI(event.fileName, event.tpi, event.weekend, progressPageId, event.client_name, event.retailer, user.nickname, file.name, retailerDataProp.client_id)
      .then((response) => {
        // var blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
        // fileSaver.saveAs(blob, "log.txt");
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "SKYBAZAAR Error log.xlsx");
        //link.setAttribute('download', "SKYBAZAARLOG.csv");
        document.body.appendChild(link);
        link.click();
        //resolve(true);
      })
  }

  const handleNext = (msgData) => {
    setBoxContaint(prevState => setValues(msgData, prevState))
  };


  const handleClose = () => {
    if (window.confirm("Do you really want to close this? Page will reload") === true) {
      if (!clicked) {
        let printOut = `${user.nickname}, ${retailerDataProp.client_id}, ${retailerDataProp.retailer}, user cancelled after file validation was completed`;
        try {
          clientLogAPI(printOut)
            .then((res) => {
              console.log("sent to log")
            });
        } catch (error) {
          console.log(error)
        }
      }
      sessionStorage.removeItem(`submitted time ${progressPageId}`)
      sessionStorage.removeItem(`mismatched combos ${progressPageId}`)
      window.location.reload();
    }
  }


  function QontoStepIcon(props) {

    let { active, completed, className, icon } = props;
    let iconProp = '';

    if (boxContaint.socketData) {
      let checkmarkArr = boxContaint.value.filter(x => x.type === "Check Mark");
      if (checkmarkArr.length > 0) {
        iconProp = checkmarkArr[checkmarkArr.length - 1].value.filter(x => x.step === icon)[0].status
      } else {
        iconProp = ''
      }
    }

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>

        {completed && iconProp !== "" ?
          iconProp === 0 ? <ErrorIcon sx={{ color: '#FFFDD0', borderRadius: 6, bgcolor: '#E49B0F', fontSize: 22 }} className="QontoStepIcon-errorIcon" />
            : iconProp === 1 ? <CheckIcon sx={{ color: '#0FB559', border: 4, borderRadius: 6, bgcolor: '#C5F1C6' }} className="QontoStepIcon-completedIcon" />
              : iconProp === -1 ? <CancelIcon sx={{ color: '#FFDDE3', borderRadius: 6, bgcolor: '#FF0F3C', fontSize: 22 }} className="QontoStepIcon-errorIcon" />
                : ''
          : (
            <div className="QontoStepIcon-circle" />
          )}
      </QontoStepIconRoot>
    );
  }

  async function submit() {
    let pickedRetailer = retailerDataProp.fileName ? retailerDataProp.fileName : retailerDataProp.retailer.replaceAll(" ", "")
    const pickedWed = retailerDataProp.weekend.replaceAll("-", "")
    let rowfilename = `${pickedRetailer} ${pickedWed}.xlsx`
    let tpid = retailerDataProp.tpi
    let retailer = retailerDataProp.retailer
    setClick(true)
    setSumitted(true)

    if (!clicked) {
      try {
        SubmitAPI(rowfilename, tpid, retailer, retailerDataProp.client_id, pickedWed, progressPageId, file.name)
          .then((response) => {
            setUpload(response)
          })
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  const downloadLogButton =
    <DownloadSummaryBtn onClick={() => { handleLog(retailerDataProp) }} sx={{ p: 2, borderRadius: 1 }}  >
      Download Summary
    </DownloadSummaryBtn>


  const dashboardButton =
    <SubmitBtn onClick={() => { handleClose(); }} sx={{ p: 2, borderRadius: 1 }} >
      Return to Dashboard
    </SubmitBtn>

  const cancelButton =
    <CancelBtn onClick={() => { handleClose(); }} sx={{ p: 2, borderRadius: 1 }} >
      Cancel
    </CancelBtn>

  const submitButton =
    <SubmitBtn onClick={() => submit()} sx={{ p: 2, borderRadius: 1 }} >
      Submit
    </SubmitBtn>

  return (
    <Grid container item spacing={2} >
      <Grid item lg={12}>
        <Box sx={{ width: '80%', margin: 'auto', paddingTop: 2.5 }}>
          <Box sx={{ width: '100%', height: '60px' }}>
            <Grid container item spacing={0} direction='column'>
              <Grid item lg={12}>
                <Stepper activeStep={boxContaint.activeStep} connector={<QontoConnector />} alternativeLabel sx={{ paddingBottom: 2 }}>
                  {steps.map((label) => {
                    return (
                      <Step key={label} >
                        <StepLabel StepIconComponent={QontoStepIcon}>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>

              <Grid item lg={12}>
                <Grid container item spacing={1} direction='row'>
                  <Grid item lg={3}>
                    <Typography align='center' fontSize={14}>File Properties</Typography>
                  </Grid>
                  <Grid item lg={3}>
                    <Typography align='center' fontSize={14}>Location and Product Check</Typography>

                  </Grid>
                  <Grid item lg={3}>
                    <Typography align='center' fontSize={14}>Sales and Stock Check</Typography>

                  </Grid>
                  <Grid item lg={3}>
                    <Typography align='center' fontSize={14}>File Ready</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid >
      <Grid item lg={12}>
        <Typography sx={{ ml: 4 }}> {retailerDataProp.fileName} {retailerDataProp.weekend} </Typography>
        <Box sx={{ height: '75vh', overflowY: 'auto', padding: 1, paddingBottom: 2 }} style={{}} id="idData">
          <Box sx={{ bgcolor: '#fff', boxShadow: 0, borderRadius: 2, width: '90%', minHeight: 200, p: 2, margin: 'auto' }}>
            {loading ?
              <div style={{ marginTop: '10%', textAlign: 'center' }}>
                <CircularProgress style={{ color: '#54B948' }} className='mb-3' />
                <div style={{ display: 'block' }}>Parsing file. Getting information...</div>
              </div> :

              boxContaint.value.map(val => {
                return (
                  val.type === "Check Mark" ? null :
                    val.category === "Invalid Tabname" ?     // if there are multiple valid tab names or no valid tab name
                      <Grid item lg={12}>
                        <Box sx={{ bgcolor: '#fff', boxShadow: 0, borderRadius: 2, maxHeight: 500 }} style={{}} >
                          <Grid container direction='column' item spacing={1} >
                            <Grid item lg={12}>
                              <Typography align='center' variant='h6' fontWeight={'bold'} sx={{ fontSize: '1.1rem', p: '35px 20px', color: '#FF0F3C' }} >Your upload was not successful. Please name only one tab: "Template", "Sheet1", or "Sheet 1".</Typography>
                            </Grid>
                            <Grid item lg={12}>
                              <Box sx={{ '& button': { m: 0.5 }, textAlign: 'center' }}>
                                <div>{dashboardButton}</div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      : val.category === "Sales and Stock Check" ?
                        (
                          <Grid item lg={12}>
                            {val.type === "Sold Qty" ? <Box> <Typography align='center' variant='h6' sx={{ fontWeight: 'bold', color: '#333' }} >{val.category}</Typography>
                              <Divider variant="middle" sx={{ marginBottom: 1, marginTop: 1 }} /> <Grid item lg={6}>
                                <Typography align='right' sx={{ mt: 0.5, mb: 1, mr: 1 }} >% Change to Last Week</Typography>
                              </Grid> </Box> : null}

                            <Box display={'flex'} sx={{ ml: 2, mt: 0.5 }}>
                              {getStatusIcon(val.status)}

                              <Grid item lg={3}><Typography sx={{ ml: 2, color: `${getStatusColor(val.status)}` }} >{val.type}</Typography></Grid>
                              <Grid item lg={2} ><Typography sx={{ ml: 3, color: `${getStatusColor(val.status)}` }}>{val.value[0]}</Typography></Grid>
                              <Grid item lg={6} ><Typography sx={{ ml: 2, color: `${getStatusColor(val.status)}` }} align='center'>{val.value[1]}</Typography></Grid>
                            </Box>

                          </Grid>
                        ) :
                        val.category === "file upload" ?
                          (
                            <Grid item lg={12}>
                              <Box sx={{ bgcolor: '#fff', boxShadow: 0, borderRadius: 2, maxHeight: 500 }} style={{}} >
                                <Grid container direction='column' item spacing={1} >
                                  <Grid item lg={12}>
                                    {getUploadSuccessComponent(val.status, upload)}
                                  </Grid>

                                  <Grid item lg={12}>
                                    {val.status === 1 && upload ?
                                      <Box sx={{ '& button': { m: 0.5 }, textAlign: 'center' }}>
                                        <div>{dashboardButton}</div>
                                        <div>{downloadLogButton}</div>
                                      </Box>
                                      : val.status === 1 ?
                                        <>
                                          <Box sx={{ '& button': { mb: 1, ml: 2, mr: 2 } }} display='flex' justifyContent={'center'}>
                                            {cancelButton}
                                            {submitButton}
                                          </Box>
                                          <Box sx={{ '& button': { m: 0.5 }, textAlign: 'center' }}>
                                            {downloadLogButton}
                                          </Box>
                                        </>

                                        : <Box sx={{ '& button': { m: 0.5 }, textAlign: 'center' }}>
                                          <div>{dashboardButton}</div>
                                          <div>{downloadLogButton}</div>
                                        </Box>
                                    }
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          ) :
                          (<Grid item lg={12}>
                            {val.type === "Column Headers" || val.type === "Mismatched Locations" ? <Box> <Typography align='center' variant='h6' sx={{ fontWeight: 'bold', color: '#333' }} >{val.category}</Typography>
                              <Divider variant="middle" sx={{ marginBottom: 1, marginTop: 1 }} /> <Grid item lg={6}>
                              </Grid> </Box> : null}
                            <Box display={'flex'} sx={{ ml: 2, mt: 0.5 }}>
                              {getStatusIcon(val.status)}
                              <Typography sx={{ ml: 2, color: `${getStatusColor(val.status)}` }}>{val.type}</Typography>
                              <Typography sx={{ ml: 1, color: `${getStatusColor(val.status)}` }}>{val.value[0]}</Typography>
                            </Box>
                            <Box sx={{ ml: 6, p: 0 }}>
                              <Typography sx={{ color: '#D3D3D3' }}>{val.value[1]}</Typography>
                            </Box>
                          </Grid>)
                )
              })
            }

          </Box>
        </Box>
      </Grid>
    </Grid >
  );
}