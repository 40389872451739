import { makeStyles } from '@material-ui/core/styles';
import SkypadLogo from '../../components/skypad_logo.png'
import {
    Button, Typography
} from '@material-ui/core';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '90vh',
        margin: 'auto',
        alignItems: 'center',
    },
}));

function Error(props) {
    const classNames = useStyles();
    let { OverlayAction } = useContext(OverlayContext);

    const state = props.location.state.response.data;
    OverlayAction.stop();

    return (
        <div classNameName={classNames.root} >
            <div className="m-auto text-center">
                <img src={SkypadLogo} width="150" className='mb-5' />
                <Typography variant='h2' sx={{ color: '#FF1943', fontSize: '2.5rem' }}>{state}</Typography>
                <div className="col-lg-12 mx-auto">
                    <Typography variant='h6'>Please contact administrator</Typography>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-5">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error;
