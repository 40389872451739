/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { HeaderContext } from '..';
import { ApiContext } from '../../../contexts/ApiContext';

export default function Header() {
    const { user, logout } = useAuth0();
    const { clientList, selectedWED, setSelectedWED, fileData, selectedClient, setSelectedClient, selectedRetailer, setSelectedRetailer } = useContext(HeaderContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [wedlList, setWedlList] = useState([]);
    const [retailerlList, setRetailerlList] = useState([]);
    const [brandlList, setBrandlList] = useState([]);
    const open = Boolean(anchorEl);
  const { sessionCheckAPI } = useContext(ApiContext);

    const helpLink = () => {
        let path = 'https://skypad.zendesk.com/hc/en-us';
        window.open(path, '_blank');
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        sessionCheckAPI(user.nickname)
            .then(
                (response) => {
                    logout({ returnTo: window.location.origin });

                },
                (reject) => {
                    console.log(reject)
                })
        // logout({ returnTo: window.location.origin });
    }

    useEffect(() => {
        setWedlList(fileData ? fileData.map(d => d.weekend).filter((val, index, self) => self.indexOf(val) === index) : [])
        setRetailerlList(fileData ? fileData.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index) : [])
        setBrandlList(fileData ? fileData.map(d => [d.client_name, d.client_id].toString()).filter((val, index, self) => self.indexOf(val) === index) : [])
    }, []);

    useEffect(() => {

        if (selectedRetailer === "All" || selectedRetailer === fileData.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index)[0]) {

            if (selectedRetailer === "All") {
                setBrandlList(clientList.map(d => [d.client_name, d.client_id].toString()).filter((val, index, self) => self.indexOf(val) === index))
            }
            else {
                setBrandlList(clientList.filter((c => c.retailer === selectedRetailer)).map(d => [d.client_name, d.client_id].toString()))
            }
        }

        if (selectedClient.key === "All" || selectedClient.key === fileData.map(d => d.client_id).filter((val, index, self) => self.indexOf(val) === index)[0]) {

            if (selectedClient.key === "All") {
                setRetailerlList(clientList.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index))
            }
            else {
                setRetailerlList(clientList.filter((c => c.client_id === selectedClient.key)).map(d => d.retailer))
            }
        }

    }, [fileData]);

    useEffect(() => {    // when there is only one Brand, auto populate it
        if (selectedRetailer && brandlList.length === 1 && brandlList[0].split(",")[1] !== selectedClient.key && brandlList[0].split(",")[0] !== selectedClient.val) {
            setSelectedClient({ key: brandlList[0].split(",")[1], val: brandlList[0].split(",")[0] })
        }
    }, [brandlList]);

    useEffect(() => {    // when there is only one retailer, auto populate it
        if (selectedClient && retailerlList.length === 1 && retailerlList[0] !== selectedRetailer && retailerlList[0] !== selectedRetailer) {
            setSelectedRetailer(retailerlList[0])
        }
    }, [retailerlList]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection='column'
                    >
                        <Grid>
                            <FormControl variant="standard" sx={{ minWidth: 110, mr: 2 }}>
                                <InputLabel>
                                    Retailer
                                </InputLabel>
                                <Select
                                    onChange={(e, x) => {
                                        // setSelectedRetailer({ key: x.props.value, val: x.props.title })
                                        setSelectedRetailer(x.props.value)
                                    }}
                                    // value={selectedRetailer.key}
                                    value={selectedRetailer.split(",").length > 1 ? 'All' : selectedRetailer}
                                >
                                    {retailerlList.length > 1 ? <MenuItem value="All" title="All" >All</MenuItem> : null}
                                    {/* <MenuItem value="All" >All</MenuItem> */}
                                    {retailerlList.sort().map((ret) => {
                                        // return (<MenuItem value={ret.split(",")[1]} title={ret.split(",")[0]}>{ret.split(",")[0]}</MenuItem>)
                                        return (<MenuItem value={ret}>{ret}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>

                            {clientList ?
                                <FormControl variant="standard" sx={{ minWidth: 110, mr: 2 }}>
                                    <InputLabel>
                                        Brand
                                    </InputLabel>
                                    <Select
                                        // value={brandlList.length === 1 ? brandlList[0].split(",")[1] : selectedClient.key}
                                        value={selectedClient.key}
                                        onChange={(e, x) => setSelectedClient({ key: x.props.value, val: x.props.title })}
                                    >
                                        {brandlList.length > 1 ? <MenuItem value="All" title="All" >All</MenuItem> : null}
                                        {/* <MenuItem value="All" title="All" >All</MenuItem> */}

                                        {brandlList.sort().map((b) => {
                                            return (<MenuItem value={b.split(",")[1]} title={b.split(",")[0]}>{b.split(",")[0]}</MenuItem>)
                                        })}
                                        {/* {uniqueClient.sort().map((rl) => {
                                            return (<MenuItem value={rl['client_id']} title={rl['client_name']}>{rl['client_name']}</MenuItem>)
                                        })} */}
                                    </Select>
                                </FormControl> :
                                <></>}
                            <FormControl variant="standard" sx={{ minWidth: 110, mr: 2 }}>
                                <InputLabel>
                                    Week-Ending
                                </InputLabel>
                                <Select
                                    //value={wedlList.length === 1 ? wedlList[0] : selectedWED.key}
                                    value={selectedWED.key}
                                    onChange={(e, x) => setSelectedWED({ key: x.props.value, val: x.props.title })}
                                >
                                    <MenuItem value="All" >All</MenuItem>

                                    {wedlList.sort().map((wedl) => {
                                        return (<MenuItem value={wedl} title={wedl}>{wedl}</MenuItem>)
                                    })}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid container style={{ width: 'auto' }} columnSpacing={1} >
                            <Grid>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        sx={{
                                            flexDirection: 'column',
                                            paddingTop: 0,
                                            borderRadius: '6px'
                                        }}
                                        aria-label="account of current user"
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        color="inherit"
                                    >
                                        <AccountCircle sx={{ fontSize: '30px', width: 32, height: 32 }} ></AccountCircle>
                                        <span className="profile-name">{user.name}</span>

                                    </IconButton>

                                </Tooltip>

                            </Grid>
                            <Grid>
                                <Tooltip title="Help">
                                    <IconButton
                                        sx={{
                                            flexDirection: 'column',
                                            paddingTop: 0,
                                            ml: 1,
                                            borderRadius: '6px'
                                        }}
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={helpLink}
                                        color="inherit"
                                    >
                                        <ContactSupportIcon sx={{ fontSize: '30px', width: 32, height: 32 }} ></ContactSupportIcon>
                                        <span className="profile-name">Help</span>

                                    </IconButton>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        fontWeight: 'bold',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon >
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}