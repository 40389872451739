import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const tabs = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //    icon: <AssessmentOutlinedIcon />,
  //    flag:''
  // },
  {
    title: 'Upload Data',
    path: '/upload',
    icon: <UploadFileOutlinedIcon />,
    flag:''
  },
];

export default tabs;